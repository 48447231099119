import './PageSectionServicesCards.scss';

import { graphql } from 'gatsby';
import React from 'react';

import { useControllers } from '../../../controllers/app.controller';
import { WpFrontPageSectionServiceCard } from '../../../types/wordpress.types';
import ServiceCard from '../../ServiceCard/ServiceCard';
import HorizontalSwiper from '../../HorizontalSwiper/HorizontalSwiper';
import PageSection from '../../PageSection/PageSection';
import { Observer } from 'mobx-react-lite';

export const PageSectionServicesCardsFragment = graphql`
  fragment PageSectionServicesCardsFragment on WpTemplate_FrontPage {
    frontPageFields {
      sectionServicesCards {
        body
        heading
        appIconName
        backgroundImage {
          ...Image_MediaItem
        }
        actionLink {
          target
          title
          url
        }
      }
    }
  }
`;

type PageSectionServicesCardsProps = {
  services: WpFrontPageSectionServiceCard[],
}

const PageSectionServicesCards = (props: PageSectionServicesCardsProps) => {
  const { UI } = useControllers();
  const p = props;
  const s = {
    get ServiceCardsView() {
      return <>
        {p.services.map((s, idx) => (<React.Fragment key={idx}>
          <ServiceCard
            service={s}
          />
        </React.Fragment>))}
      </>
    }
  }
  return <Observer children={() => (
    <PageSection id="SectionServicesCards" className="page-section SectionServicesCards" observeVisibility>
      {UI.uptoDesktop
        ? <HorizontalSwiper>
          {s.ServiceCardsView}
        </HorizontalSwiper>
        : <div className='SectionServicesCardsContainer'>
          {s.ServiceCardsView}
        </div>
      }
    </PageSection>
  )} />
}

export default PageSectionServicesCards;