import { Observer } from 'mobx-react-lite';
import React from 'react';
import sanitizeHtml from 'sanitize-html';
import { pipe } from '../../scripts/helpers/pipe';
import { WpFrontPageSectionServiceCard } from '../../types/wordpress.types';
import joinClassNames from '../../utils/className.utils';
import { sanitize } from '../../utils/sanitize.utils';
import { fromCharCode } from '../../utils/string.utils';
import BaseLink from '../BaseLink/BaseLink';
import BaseButton from '../BaseButton/BaseButton';
import BaseImage from '../BaseImage/BaseImage';
import './ServiceCard.scss';
import APP_CONTENT_CONFIG from '../../constants/appContentConfig.constants';

type ServiceCardProps = {
  service: WpFrontPageSectionServiceCard,
}

const ServiceCard: React.FC<ServiceCardProps> = props => {

  const p = props;
  const s = {
    get service() { return p.service },
    get title() { return s.service.heading },
    get icon() { return s.service.appIconName },
    get body() { return s.service.body },
    get featuredImage() { return s.service.backgroundImage },
    get actionLink() { return s.service.actionLink },
    renderString(value: string) {
      return value ? pipe(fromCharCode, sanitizeHtml)(value) : "";
    },
  }
  const { sectionServicesCards } = APP_CONTENT_CONFIG.page.frontPage;
  return <Observer children={() => (
    <div className={joinClassNames("ServiceCard")}>
      <div className="ServiceCard__inner">
        <main className="ServiceCard__main">
          <div className="ServiceCard__header">
            {s.icon && sectionServicesCards.content.header.IconFactory(s.icon)}
            <h1 className="ServiceCard__title">{s.title}</h1>
          </div>
          <div className="ServiceCard__footer">
            <div className="ServiceCard__afterTitle" dangerouslySetInnerHTML={{ __html: sanitize(s.body) }} />
            {s.actionLink && <BaseLink title={s.actionLink.title} to={s.actionLink.url}>
              <BaseButton>{s.actionLink.title}</BaseButton>
            </BaseLink>}
          </div>
        </main>
        <figure className="ServiceCard__figure">
          {s.featuredImage && <BaseImage
            className="filler ServiceCard__figure__image-filler"
            media={s.featuredImage}
            imageType='gatsbyDynamic'
          />}
        </figure>
      </div>
    </div>
  )} />
}

export default ServiceCard;